<template>
  <v-container
    id="streaming"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-row>
      <v-col cols="12">
        <v-col cols="12">
          <v-card style="margin-top: 0; padding: 20px;">
            <v-col cols="12">
              <h4>
                • {{ str['streaming_info_1'] }}
              </h4>
              <h4 class="mt-4">
                • {{ str['streaming_info_2'] }}
              </h4>
              <h4 class="mt-4 mb-8">
                • {{ str['streaming_info_3'] }}
              </h4>
              <div>
                <v-text-field
                  v-model="streamingUrl"
                  :label="str['streaming_url']"
                />
              </div>
              <div class="mt-6 mb-2">
                <h4>
                  {{ str['customize_notification'] }}
                </h4>
              </div>
              <div>
                <v-text-field
                  v-model="notification.title"
                  :label="str['notification_title']"
                />
              </div>
              <div>
                <v-text-field
                  v-model="notification.body"
                  :label="str['notification_message']"
                />
              </div>
              <div class="mt-6 text-center">
                <v-btn
                  color="error"
                  style="margin-right: 10px;"
                  @click="finishStreaming"
                >
                  {{ str['finish_streaming'] }}
                </v-btn>
                <v-btn
                  color="success"
                  @click="save"
                >
                  {{ str['start_streaming'] }}
                </v-btn>
              </div>
              <div
                v-if="message && message.streaming"
                class="mt-5 text-center success--text"
              >
                {{ message.streaming }}
              </div>
              <div
                v-if="failed"
                class="mt-5 text-center warning--text"
              >
                {{ str['to_start_streaming_set_url'] }}
              </div>
              <div
                v-if="message && message.notification"
                class="mt-2 text-center success--text"
              >
                {{ str['notification_sent_success'] }}
              </div>
              <div
                v-if="message && !message.notification"
                class="mt-2 text-center warning--text"
              >
                {{ str['notification_needs_title_message_warning'] }}
              </div>
            </v-col>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'
  import axios from 'axios'

  export default {
    data () {
      return {
        str: window.strings,
        user: Utils.getUser(),
        streamingUrl: '',
        notification: {
          title: '',
          body: '',
        },
        message: false,
        failed: false,
      }
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.setData()
    },
    methods: {
      setData: function () {
        try {
          if (this.user && this.user.microcycle) {
            const parseData = JSON.parse(this.user.microcycle)
            this.streamingUrl = parseData &&parseData.streaming ? parseData.streaming : ''
          }
        } catch (error) {
          this.streamingUrl = ''
        }
      },
      finishStreaming: function () {
        this.streamingUrl = ''
        this.save()
      },
      save: function (start) {
        const self = this

        this.failed = false
        this.message = false

        if (start && !this.streamingUrl) {
          this.failed = true
          setTimeout(function () {
            self.failed = false
          }, 4000)
          return false
        }

        const payload = {
          microcycle: JSON.stringify({
            streaming: this.streamingUrl
          })
        }

        this.$isLoading(true)
        Api.updateUser(payload, function (response) {
          if (response.success) {
            const user = Utils.getStorage('user')
            user.microcycle = response.data.microcycle
            Utils.setStorage('user', user)
            self.sendNotification(start)
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      sendNotification: function (start) {
        const self = this
        const notification = {
          // users: [],
          pt_id: axios.defaults.ptId,
          title: self.notification.title,
          body: self.notification.body,
        }

        if (notification.title && notification.body) {
          Api.sendAppNotification(notification, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.message = {
                streaming: start ? window.strings['streaming_started'] : window.strings['streaming_finished'],
                notification: true,
              }
              setTimeout(function () {
                self.message = false
              }, 4000)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          self.$isLoading(false)
          self.message = {
            streaming: start ? window.strings['streaming_started'] : window.strings['streaming_finished'],
            notification: false,
          }
          setTimeout(function () {
            self.message = false
          }, 8000)
        }
        
      },
    },
  }
</script>
